<header>
  <h1 class="brand">Chandrabeverage</h1>
  <span class="spacer"></span>
  <a
    class="link"
    routerLink="/"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Home
  </a>
  <a
    class="link"
    routerLink="/contacts"
    routerLinkActive="active"
    routerLinkActive="active"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    Contacts
  </a>
</header>
