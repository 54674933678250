// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCtRuNGW9YX3oPgV_Tp2dZNQ6-Q5RhxR4s",
    authDomain: "chandrabeverage-efc48.firebaseapp.com",
    projectId: "chandrabeverage-efc48",
    storageBucket: "chandrabeverage-efc48.appspot.com",
    messagingSenderId: "706057039259",
    appId: "1:706057039259:web:cc15dc387f35178a60053e"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
